export default {

    hasInfo(game) {
        if (!game?.game_type_id) return false;

        switch (+game.game_type_id) {
        case 1: return false; // if is a Jumanji game
        case 3: return true; // if is a Tablero-Dados-Preguntas game
        default: return false;
        }
    },

    hasCards(game) {
        if (!game?.game_type_id) return false;

        switch (+game.game_type_id) {
        case 1: return true; // if is a Jumanji game
        case 3: return true; // if is a Tablero-Dados-Preguntas game
        default: return false;
        }
    },
    hasPlayers(game) {
        if (!game?.game_type_id) return false;

        switch (+game.game_type_id) {
        case 3: return false; // if is a Tablero-Dados-Preguntas game
        default: return true;
        }
    }
}
